import ServesCard from "./ServesCard";

const serversData = [
  {
    id: 1,
    img: "/serves/serves-1.svg",
    title: "Rental Cars from $5.99",
    desc: "We compare prices from various rental car providers, allowing you to find the best deals.",
  },
  {
    id: 2,
    img: "/serves/serves-2.svg",
    title: "Save Time",
    desc: "With us, you can easily compare prices and book a rental car in just a few clicks and save valuable time.",
  },
  {
    id: 3,
    img: "/serves/serves-3.svg",
    title: "Wide selection",
    desc: "We have a wide selection of car rental options, allowing you to choose the one that best suits you.",
  },
  {
    id: 4,
    img: "/serves/serves-4.svg",
    title: "Personalized Deals",
    desc: "We provide personalized recommendations for the perfect rental car to fit your needs.",
  },
];

// id 2 and id 3 has duplicate content

type Props = {
  price?: string;
};

const RentServesYou = ({ price }: Props) => {
  return (
    <section id="rent80-serves-you" className="px-[13px]">
      <div className="max-w-7xl mx-auto mt-[22px] mb-[18px] lg:my-[60px]">
        <h2 className="font-extrabold text-lg lg:text-[30px] text-my-dark-blue">
          What Rent80 serves you
        </h2>
        <div className="md:grid grid-cols-2 lg:grid-cols-4 gap-6 rounded-[16px] md:rounded-none overflow-clip mt-[18px] lg:mt-10">
          {serversData.map((data) => {
            return (
              <ServesCard
                key={data.id}
                img={data.img}
                title={data.title}
                desc={data.desc}
                price={price}
                id={data.id}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default RentServesYou;
